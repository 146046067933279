/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i");

// @font-face {
//   font-family: "ALKAMANAS";
//   font-style: thin;
//   font-weight: 100;
//   src: url("./assets/font/ALKAMANAS-Thin.otf") format("opentype");
// }
// @font-face {
//   font-family: "ALKAMANAS";
//   font-style: light;
//   font-weight: 200;
//   src: url("./assets/font/ALKAMANAS-ExtraLight.otf") format("opentype");
// }
// @font-face {
//   font-family: "ALKAMANAS";
//   font-style: book;
//   font-weight: 300;
//   src: url("./assets/font/ALKAMANAS-UltraLight.otf") format("opentype");
// }
// @font-face {
//   font-family: "ALKAMANAS";
//   font-style: regular;
//   font-weight: 400;
//   src: url("./assets/font/ALKAMANAS-Regular.otf") format("opentype");
// }
@font-face {
  font-family: "Visetra";
  font-style: light;
  font-weight: 100;
  src: url("./assets/font/Visetra-Light.ttf") format("opentype");
}
@font-face {
  font-family: "Visetra";
  font-style: regular;
  font-weight: 400;
  src: url("./assets/font/Visetra-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Visetra";
  font-style: semi-bold;
  font-weight: 600;
  src: url("./assets/font/Visetra-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: "Visetra";
  font-style: bold;
  font-weight: 800;
  src: url("./assets/font/Visetra-Bold.ttf") format("opentype");
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

$font-path: "./assets/font";

// $font-family-md-base: "ALKAMANAS";
// $font-family-ios-base: "ALKAMANAS";
// $font-family-wp-base: "ALKAMANAS";

// html.plt-mobile ion-app {
//   -webkit-user-select: text;
//   -moz-user-select: text;
//   -ms-user-select: text;
//   user-select: text;
// }

.header-translucent-ios ion-toolbar {
  --opacity: 1;
}

html {
  --ion-font-family: "Visetra" !important;
  -webkit-tap-highlight-color: transparent;

  //--ion-font-family: "Inter", sans-serif !important;
}

ion-app {
  display: block !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ion-router-outlet {
    .ion-page {
      .has-footer {
        --padding-bottom: 84px;
      }

      .has-sub-header-50 {
        --padding-top: 120px;
      }
    }

    &.nav-included {
      //margin-top: 80px;
      .ion-page {
        ion-content {
          // --background: linear-gradient(
          //   335deg,
          //   rgb(8, 31, 60) -7%,
          //   rgb(73 12 0) -15%,
          //   rgb(0, 0, 0) 41%
          // );

          &:not(.has-sub-header-50) {
            //--padding-top: 70px;
          }
        }
      }
    }
  }
}

b,
strong {
  font-weight: 900;
}

img {
  -webkit-user-drag: none;
}

form,
.form {
  //padding: 0 calc((100% - 600px) / 2);

  ion-item {
    &.ion-invalid {
      &:not(.at-beginning) {
        --border-color: rgb(150, 0, 0) !important;
        --color: rgb(150, 0, 0) !important;

        &:not(.item-has-value) {
          //&::after {
          //  display: block;
          //  content: "*Bu alan zorunludur!";
          //  font-style: italic;
          //  font-size: initial;
          //  margin-top: 8px;
          //}
        }

        &.item-has-value {
          &:not(.item-has-focus) {
            &::after {
              display: block;
              content: "*İstenilen formatta değil";
              font-style: italic;
              font-size: initial;
              margin-top: 8px;
            }
          }
        }
      }
    }

    &.item-has-focus,
    &.item-has-value {
      ion-label {
        background: -webkit-linear-gradient(
          45deg,
          var(--ion-color-primary) 0%,
          var(--ion-color-primary-shade) 52%,
          var(--ion-color-primary-tint) 100%
        ) !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        display: inline;
      }
    }

    &.item-has-focus {
      border-image-source: -linear-gradient(
        45deg,
        var(--ion-color-primary) 0%,
        var(--ion-color-primary-shade) 52%,
        var(--ion-color-primary-tint) 100%
      ) !important;
      --border-color: var(--ion-color-primary) !important;
      transition: width 0.5s ease;
    }

    &:not(.ion-invalid) {
      &:not(.item-has-focus) {
        &:not(.item-has-value) {
          &:not(.warn-item) {
            ion-label {
              --color: var(--ion-color-medium) !important;
            }
          }
        }
      }
    }
  }

  &.submitting {
    ion-grid {
      opacity: 0.3;
    }

    .processing {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      z-index: 9;

      .spinner {
        height: 100%;
        font-size: 60px;
        color: var(--ion-color-primary);
      }
    }
  }

  ion-button {
    margin-top: 20px;
  }
}

ion-header {
  z-index: 102;

  &.transparent {
    ion-toolbar {
      --background: transparent !important;
    }
  }

  ion-toolbar {
    --min-height: 70px;
    //--background: var(--ion-background-color);
    //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    --border-width: 0 !important;
    --background: var(--ion-transparent-background-color);
    --opacity: 1;

    ion-buttons {
      &.nav-links {
        padding-inline-start: 50px;

        .nav-link-divider {
          background: var(--ion-color-light-contrast);
          height: 1.4em;
          width: 1px;
          margin: 0 12px;
          opacity: 0.7;
          display: inline-block;
        }

        .nav-link {
          --color: var(--ion-color-light-contrast);
          font-size: 16px;
          font-weight: 400;
          //padding: 0 5px;
          height: 2em !important;
          --padding-start: 1.1em !important;
          --padding-end: 1.1em !important;
          --border-radius: 10px !important;
          margin-inline: 0;

          ion-icon {
            &.chevron {
              font-size: 15px;
              margin-inline-start: 0.4em;
              transition: transform 0.3s ease-in-out;

              &.open {
                transform: rotate(-180deg);
              }
            }
          }

          &:hover {
            --color: var(--ion-color-primary);
          }

          &.active {
            //--color: var(--ion-color-primary);

            &::after {
              background-color: var(--ion-color-primary);
              content: "";
              display: block;
              height: 5px;
              width: 5px;
              position: absolute;
              top: 45%;
              border-radius: 50%;
            }
          }

          &.opened {
            --color: var(--ion-color-primary);

            .chevron {
              transform: rotate(-180deg);
              color: var(--ion-color-primary);
            }
          }

          &.ion-activated {
            --color: var(--ion-color-primary) !important;
          }

          &::after {
            // background-color: var(--ion-color-primary);
            // content: "";
            // display: block;
            // height: 2px;
            // width: 15px;
            // position: relative;
            // top: 6;
            // left: 50%;
            // margin-left: -5px;
          }
        }
      }

      ion-button {
        &.brandmark-button {
          --padding-start: 0;
          margin-inline-start: 0;
        }

        &.inapp-logotype-button {
          --padding-start: 0;
          margin-inline-start: 0;
          font-weight: 600;

          .inapp-logotype__brandmark {
            font-size: 35px;
            color: var(--ion-color-primary);
            margin-inline-end: 10px;
          }
        }

        &.cta-link {
          --color: var(--ion-color-light-contrast);
          margin-inline-start: 10px;
          margin-inline-end: 0px;
          --padding-start: 18px;
          --padding-end: 18px;
          --padding-top: 10px;
          --padding-bottom: 10px;
          --border-radius: 20px !important;
          height: 37px;
          font-size: 15px;

          ion-icon {
            margin-inline-start: 0.3em;
            font-size: 15px;
            transition: all 0.22s ease-in-out;
          }
        }

        &.sub-cta-link {
          --color: var(--ion-color-light-contrast);
          margin-inline-start: 10px;
          margin-inline-end: 0px;
          --padding-start: 13px;
          --padding-end: 13px;
          --border-radius: 20px !important;
          height: 30px;
          font-size: 12px;

          ion-icon {
            margin-inline-start: 0.3em;
            font-size: 15px;
            transition: all 0.22s ease-in-out;
          }
        }

        &:hover {
          --color: var(--ion-color-primary);
          opacity: 1;

          ion-icon {
            &:not(.assistive) {
              color: var(--ion-color-primary);
            }

            &.assistive {
              margin-left: 5px;
            }
          }
        }

        ion-icon {
          color: var(--ion-color-light-contrast);

          &.menu-icon {
            font-size: 20px !important;
          }

          &.brandmark {
          }

          &.logotype {
            font-size: 115px !important;
          }
        }
      }

      .lang-selector {
        opacity: 0.8;
        &::part(text) {
          color: var(--ion-color-light-contrast);
        }
      }
    }
  }

  &.subnav {
    position: sticky;
    top: 70px;

    ion-toolbar {
      --min-height: 60px;
      //--background: var(--ion-background-color);
      //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
      --border-width: 0 !important;
      --background: var(--ion-light-transparent-background-color);
      --opacity: 1;

      ion-buttons {
        &.subnav-links {
          padding-inline-start: 50px;

          .nav-link-divider {
            background: var(--ion-color-light-contrast);
            height: 1.4em;
            width: 1px;
            margin: 0 12px;
            opacity: 0.7;
            display: inline-block;
          }

          .nav-link {
            --color: var(--ion-color-light-contrast);
            font-size: 15px;
            font-weight: 400;
            //padding: 0 5px;
            height: 2em !important;
            --padding-start: 0.6em;
            --padding-end: 0.6em;
            //--border-radius: 10px !important;
            margin-inline: 0;

            &:last-child {
              --padding-end: 0 !important;
            }

            ion-icon {
              &.chevron {
                font-size: 15px;
                margin-inline-start: 0.4em;
                transition: transform 0.3s ease-in-out;

                &.open {
                  transform: rotate(-180deg);
                }
              }
            }

            &.active {
              //--color: var(--ion-color-primary);

              &::after {
                background-color: var(--ion-color-primary);
                content: "";
                display: block;
                height: 5px;
                width: 5px;
                position: absolute;
                top: 45%;
                border-radius: 50%;
              }
            }

            &.opened {
              --color: var(--ion-color-primary);

              .chevron {
                transform: rotate(-180deg);
                color: var(--ion-color-primary);
              }
            }

            &.ion-activated {
              --color: var(--ion-color-primary) !important;
            }

            &::after {
            }
          }
        }
      }
    }
  }
}

ion-modal {
  //transition: all .2s ease-in-out;
  backdrop-filter: blur(4px);

  ion-backdrop {
    background: var(--ion-color-step-100);
  }
}

.action-sheet-button.sc-ion-action-sheet-ios {
  background: var(--button-background);
  color: var(--ion-color-dark);
}

.spinner {
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

ng-progress {
  .ng-progress-bar {
    .ng-bar-placeholder {
      .ng-bar {
        background-color: var(--ion-color-primary) !important;
      }
    }
  }
}

.max-container {
  padding: 0 calc((100% - 1400px) / 2);
}

.container {
  padding: 0 calc((100% - 1350px) / 2);
}

.narrow-container {
  padding: 0 calc((100% - 850px) / 2);
}

.max-narrow-container {
  padding: 0 calc((100% - 550px) / 2);
}

.nested-container {
  --padding-start: calc((100% - 1350px) / 2);
  --padding-end: calc((100% - 1350px) / 2);
}

.inner-padding {
  margin-inline-start: -15px;
  margin-inline-end: -15px;

  ion-col {
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-end: 25px;
    padding-inline-start: 25px;

    &.ip-5 {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }

    &.ip-10 {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }
}

.inter-font {
  font-family: "Inter", sans-serif !important;
}

.bordered {
  img {
    border: 3px solid !important;
  }

  &.active {
    img {
      border-color: white !important;
    }
  }
  &.passive {
    img {
      border-color: transparent !important;
    }
  }
}

.brandmark-mask {
  mask-repeat: no-repeat;
  mask-position: center;

  &.visetra {
    mask-image: url(./assets/icon/brandmark.svg);
  }

  &.space-exptool {
    mask-image: url(./assets/icon/space-exptool-brandmark.svg);
  }

  &.product-exptool {
    mask-image: url(./assets/icon/product-exptool-brandmark.svg);
  }

  &.meter {
    mask-image: url(./assets/icon/meter-brandmark.svg);
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.centralized {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vertical-centralized {
  display: flex;
  //justify-content: center;
  align-items: center;
}

.horizontal-centeralized {
  display: flex;
  justify-content: center;
}

.soon {
  opacity: 0.35;

  &::before {
    content: "Yakında";
    display: block;
    font-size: xx-large;
    font-weight: 600;
    /* transform: rotate(-35deg); */
    z-index: 999;
    position: absolute;
    color: var(--ion-color-primary);
  }
}

ion-button {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  height: 3.2em;
  --padding-start: 1.3em;
  --padding-end: 1.3em;
  //--background-activated: var(--ion-color-primary);
  font-weight: 600;

  &.not-padding {
    height: 2em;
    --padding-start: 0;
    --padding-end: 0;
  }

  &.button-clear {
    --color: var(--ion-color-dark);
    --background-activated: transparent;
  }

  &:not([fill="clear"]) {
    &.ion-activated {
      --color: white !important;
    }
  }

  ion-icon[slot="end"] {
    margin-inline: 0.3em 0 !important;
  }
}

ion-item {
  --padding-start: 0;
  --inner-padding-start: 10px;
  --background: transparent;

  &.clickable {
    &:hover {
      ion-icon {
        color: var(--ion-color-primary) !important;
      }
      --background: var(--ion-item-hover-background) !important;
    }
  }
}

section {
  &:not(.landing) {
    &:not(.no-pad) {
      padding-top: 3%;
      padding-bottom: 3%;

      @media screen and (max-width: 767px) {
        padding-top: 10%;
        padding-bottom: 10%;
      }
    }
  }

  &.pad-bot {
    padding-bottom: 6% !important;
  }

  &.no-pad-top {
    padding-top: 0% !important;
  }

  &.no-pad-bot {
    padding-bottom: 0% !important;
  }

  &.half-pad-bot {
    padding-bottom: 2% !important;
  }

  .section__row {
    .section__col {
      //padding: 35px;

      * {
        max-width: 100%;
      }

      video {
        width: 100%;
      }
    }
  }

  &.top-left {
    background: url(assets/images/backgrounds/top-short.jpg);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  &.top-left-2 {
    background: url(assets/images/backgrounds/top-left-short-3.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.top-right {
    background: url(assets/images/backgrounds/top-right-short.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.top-center {
    background: url(assets/images/backgrounds/top-center-short.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.centered-3 {
    background: url(assets/images/backgrounds/centered-3.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 767px) {
      background-size: 367%;
      background-position: 50% 20%;
      background-repeat: no-repeat;
    }
  }
}

.landing {
  &:not(.pagehead) {
    // padding-top: 7%;
    // padding-bottom: 7%;

    @media only screen and (min-width: 1024px) {
      height: 100%;
      .landing-grid {
        height: 100%;
        .landing-row {
          height: 100%;
        }
      }
    }
  }

  &.pagehead {
    // padding-top: 3%;
    // padding-bottom: 3%;
    padding-top: 6%;
    padding-bottom: 6%;

    @media only screen and (max-width: 767px) {
      padding-top: 25%;
    }
  }

  .landing-grid {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    padding-top: 0;
    padding-bottom: 0;

    .landing-background {
      position: absolute;
      width: 100%;
      height: 70%;
      top: 50px;
      left: 0;
      /* background: #191919; */
      z-index: 0;
      border-radius: 10px;
      //background-image: url(assets/images/hero-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .landing-row {
      .landing-col {
        z-index: 1;

        &.panaroma-container {
          panaroma,
          product {
            max-height: 600px;
            width: 100%;
          }

          sample-product {
            &.home-hero {
              min-height: 400px;
            }
          }
        }

        .landing__brandmark {
          color: var(--ion-color-primary);
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.hgroup {
  max-width: 718px;
  margin-left: 0;

  &.large {
    .hgroup__heading {
      font-size: 72px;
      line-height: 110%;
      letter-spacing: -0.04em;
      margin-block-end: 8px;
      font-weight: 800;
    }

    .hgroup__desc {
      font-size: 22px;
      line-height: 160%;
      letter-spacing: -0.02em;
      //color: var(--ion-color-medium);
      font-weight: 400;

      &.no-mar-bot {
        margin-block-end: 0;
      }
    }

    .hgroup__cta {
      font-size: 23px;
    }
  }

  &.medium {
    .hgroup__heading {
      font-size: 63px;
      font-weight: 700;
      margin: 18px 0 8px;
      letter-spacing: -0.03em;
      margin-block-start: 0;

      @media only screen and (max-width: 767px) {
        font-size: 43px;
      }
    }

    .hgroup__desc {
      font-size: 21px;
      line-height: 34px;
      letter-spacing: -0.02em;
      color: var(--ion-color-medium);
      font-weight: 400;

      &.no-mar-bot {
        margin-block-end: 0;
      }

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    .hgroup__cta {
      font-size: 19px;
      line-height: 100%;
      margin-top: 0;
      padding-top: 0;
      display: inline-block;
      position: relative;
      background: 0 0;
      letter-spacing: -0.03em;
      font-weight: 600;
      position: relative;
      height: 2.8em;
      --padding-start: 25px;
      --padding-end: 20px;
      --border-radius: 50px;
      text-transform: capitalize;
      margin-block-start: 21px;
      margin-block-end: 25px;
    }
  }

  &.centered {
    text-align: center;
    max-width: 100%;
    padding-inline: calc(calc(100% - 600px) / 2);
  }

  &.sticky {
    position: sticky;
    top: 6%;
  }
}

tour,
panaroma,
product,
sample-product,
navbar {
  display: block;

  &:focus {
    outline: none;
  }
}

navbar {
  // position: sticky;
  // top: 0;
  // z-index: 99;
}

.block {
  background: var(--ion-infocard-background);
  //box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;

  .block__header {
    padding: 20px 20px 16px;

    .block__title {
      --color: var(--ion-text-color, #000);
      margin: 0px;
      padding: 0px;
      font-size: 33px;
      font-weight: 700;
      line-height: 1.2;
    }
  }

  .block__content {
    padding-top: 0;
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 1.4;
  }
}

.integrations {
  .integrations__row {
    margin-block-start: 10px;
    .integrations__col {
      text-align: center;
      .integration {
        transition: filter 0.2s ease-in-out;
        font-size: 45px;
        text-align: center;
        filter: var(--integration-filter);
        margin-block-start: 25px;

        &:hover {
          filter: grayscale(0) brightness(1);
        }
      }
    }
  }
}

.hotspot-point {
  transition: all 0.3s ease-in-out;

  &:not(.scene-link) &:not(.section-link) {
    background: transparent;
    // box-shadow: 0px 3px 25px -5px rgba(0, 0, 0, 0.75);
    // border-radius: 50%;
    // padding: 5px;
    // border: 2px solid;
    // border-color: rgba(255, 255, 255, 0.75);
  }

  &.scene-link {
    //transition: opacity 0.2s ease-in-out;
    background-image: url(assets/icons/placeholder-3d.png);
    width: 90px;
    height: 107px;
    background-position: 0px 0px;
    background-size: 90px 107px;

    &:not(.loaded) {
      opacity: 0.5;
      cursor: wait !important;
    }

    &.loaded {
      opacity: 1;

      &:hover {
        width: 98px;
        height: 116px;
        background-size: 98px 116px;
        cursor: pointer;

        .hotspot-point__img {
          width: 66px;
          height: 66px;
          background-position: 0px 0px !important;
          background-size: cover !important;
          border-radius: 50%;
          /* margin: 5px 0px 0px 13px; */
          position: absolute;
          top: 16px;
          left: 16px;
        }

        .scene-link__name {
          width: 53px;
          height: 53px;
          margin: 14px 0px 0px 13px;
          //padding: 16px 1px ;
          font-size: 11.15px;
        }
      }
    }

    .hotspot-point__img {
      transition: all 0.3s ease-in-out;
      width: 58px;
      height: 58px;
      background-position: 0px 0px !important;
      background-size: cover !important;
      border-radius: 50%;
      /* margin: 13px 0px 0px 12px; */
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .scene-link__name {
      transition: all 0.3s ease-in-out;
      position: absolute;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      top: 16px;
      left: 16px;
      padding: 12px 1px;
      font-size: 12px;
      text-align: center;
      background: #00000045;
      color: #fff;
      z-index: 9;
      text-shadow: 0px 0px 4px black;
      font-weight: 600;
      display: none;
    }
  }

  &.section-link {
    backdrop-filter: blur(5px);
    border-radius: 8px;
    ion-item {
      max-width: 190px;
      --inner-padding-top: 8px;
      --inner-padding-bottom: 8px;
      --inner-padding-start: 10px;
      --inner-padding-end: 0;
      --background: #000000a1;
      //border: 3px solid;
      //box-shadow: 0 0 4px #00000061;
      text-shadow: 0 0 4px #0000009e;
      font-size: 15px;
    }

    &:hover {
      ion-item {
        --background: var(--ion-color-primary);
      }

      ion-icon {
      }
    }

    .section-link__card {
      margin: 0;
      width: 180px;
      --background: #0000008f;
      box-shadow: 0px 1px 20px 3px #00000078;

      &:hover {
        .section-link__icon {
          left: -150px;
        }
      }

      .section-link__img {
        height: 101px;
        width: 100%;
        object-fit: cover;
      }

      .section-link__icon {
        // transition: all ease-in-out 2s;
        // position: absolute;
        // left: -100px;
        // top: -84px;
        // font-size: 392px;
        // mix-blend-mode: saturation;
        // color: #ffffff3d;

        transition: all ease-in-out 2s;
        position: absolute;
        left: -239px;
        top: 85px;
        width: 500px;
        height: 84px;
        mix-blend-mode: saturation;
        color: #ffffff3d;
      }

      .section-link__card-header {
        padding: 8px 10px 15px;
        // background: url(assets/icons/forwards.svg);
        // background-size: cover;
        // background-repeat-y: no-repeat;

        .section-link__card-title {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 110%;
        }
      }
    }
  }

  &.sections-menu {
    ion-button {
      color: white;
      --border-radius: 0;
      --border-width: 3px;
      box-shadow: 0 0 8px #00000057;

      &:hover {
        --border-radius: 0;
        background: var(--ion-color-primary);

        ion-icon {
        }
      }
    }
  }

  &.info-panel {
    ion-button {
      color: white;
      //--border-radius: 50%;
      --background-activated: transparent;

      ion-icon {
        //box-shadow: 0 0 8px #00000057;
      }

      &:hover {
        //--border-radius: 0;
        //background: var(--ion-color-primary);

        ion-icon {
          color: var(--ion-color-primary);
        }
      }
    }
  }

  .hotspot__media-container {
    display: none;

    .hotspot__media-close {
      position: absolute;
      right: -50px;
      top: -50px;
    }

    .hotspot__video {
    }

    .hotspot__photo {
      max-width: 250px;
      box-shadow: 0px 1px 6px 2px #00000040;
    }

    &.opened {
      display: block;
    }
  }

  .hotspot__icon {
    padding: 5px;
    border: 2px solid;
    border-radius: 50%;
    font-size: x-large;
    transition: font-size 0.3s ease-in-out;
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.75);

    &.hidden {
      display: none;
    }
  }

  .explanation {
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    //width: -webkit-fill-available;
    font-size: 15px;
    opacity: 0;
    width: 200px;
    left: -81px;
    text-align: center;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.75);
  }

  &:hover {
    cursor: pointer;

    &:not(.written-infospot) {
      ion-icon {
        //font-size: xx-large;
      }
    }
    border-color: rgba(255, 255, 255, 1);

    ion-icon {
      color: rgba(255, 255, 255, 1);
    }

    .explanation {
      opacity: 1;
    }
  }
}

.leading-block {
  .leading-block__product-logo {
    background: #101010;
    backdrop-filter: blur(421px);
    border-radius: 22.37%;
    width: 120px;
    height: 120px;
    margin-inline-end: 25px;
    padding: 12px;

    @media screen and (max-width: 767px) {
      width: 85px;
      height: 85px;
    }
  }
  .leading-block__title {
    font-size: 82px;
    line-height: 100%;
    letter-spacing: -0.04em;
    font-weight: 900;
    -webkit-margin-after: 24px;
    margin: 0;
    margin-block-end: 4px;
    margin-block-start: 0;

    &.leading-block__product-name {
      background: url(assets/images/backgrounds/gradient.jpg);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      background-size: cover;
      display: inline;
    }
  }

  .leading-block__content {
    transition-delay: 0.4s;
    letter-spacing: -0.02em;
    font-size: 26px;
    line-height: 135%;
    margin-inline-end: 0;
    color: var(--ion-color-medium);
    //text-align: justify;
  }

  .leading-block__buttons {
    ion-button {
      margin-right: 10px;
      margin-inline-start: 0;
      margin-inline-end: 10px;
    }
  }

  .leading-block__cta {
    margin-block-start: 0;
    margin-block-end: 0;
    // font-size: 24px;
    // font-weight: 400;
    font-size: larger;
    --border-radius: 30px;
    height: 2.9em;
    margin-inline-end: 10px;
  }

  &.centered {
    text-align: center;

    .leading-block__content {
      margin-inline-end: 0;
    }
  }
}

.info-block__container {
  .info-block__icon-container {
    text-align: center;

    .info-block__icon {
      // min-height: 100%;
      // max-width: 100%;
      //font-size: 280px;
      // width: 100%;
      // height: 100%;
      font-size: 390px;
      height: 390px;
    }
  }

  .info-block__img-container {
    text-align: center;

    .info-block__img {
    }
  }

  .info-block {
    .info-block__subtitle {
      font-size: 13px;
      letter-spacing: 0.16em;
      text-transform: uppercase;
      margin-block-start: 5px;
    }

    .info-block__title {
      font-weight: 600;
      font-size: 55px;
      line-height: 100%;
      letter-spacing: -0.05em;
      margin: 0;
    }

    .info-block__content {
      font-size: 23px;
      line-height: 135%;
      letter-spacing: -0.02em;
      margin-bottom: 10px;
      margin-block-start: 20px;
      color: var(--ion-color-medium);
      //text-align: justify;

      &.single-35 {
        margin-block-start: 35px;
      }

      &.single-20 {
        margin-block-start: 20px;
      }

      &.narrow {
        padding-inline: calc(calc(100% - 630px) / 2);
      }
    }

    .info-block__cta {
      font-size: 24px;
      line-height: 100%;
      margin-top: 0;
      padding-top: 0;
      display: inline-block;
      position: relative;
      background: 0 0;
      letter-spacing: -0.03em;
      font-weight: 600;
      position: relative;
      height: 2em;
      --padding-start: 0;
      --padding-end: 0;
    }

    .info-block__button {
      font-size: 19px;
      line-height: 100%;
      margin-top: 0;
      padding-top: 0;
      display: inline-block;
      position: relative;
      background: 0 0;
      letter-spacing: -0.03em;
      font-weight: 600;
      position: relative;
      height: 2.8em;
      --padding-start: 20px;
      --padding-end: 20px;
      --border-radius: 50px;
      text-transform: capitalize;
      -webkit-margin-before: 15px;
      margin-block-start: 15px;
      margin-inline: 5px;
    }

    &.centered {
      text-align: center;
      padding: 0 12%;

      .info-block__content {
        text-align: center;
      }
    }

    &.sticky {
      position: sticky;
      top: 6%;
    }
  }
}

.image-block {
  // padding-inline-start: 10px;
  // padding-inline-end: 10px;

  .image-block__img-container {
    width: 100%;

    .image-block__img {
      width: 100%;
    }
  }

  .image-block__title {
    font-size: 26px;
    margin-bottom: 11px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
  }

  .image-block__content {
    font-size: 17px;
    color: var(--ion-color-medium);
    line-height: 29px;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
    //text-align: justify;
    text-align-last: justify;
  }
}

.iconitem {
  display: flex;
  padding: 20px 0;

  .iconitem__icon-container {
    margin-inline-end: 25px;
    height: 100%;
    margin-top: 5px;

    ion-icon {
      font-size: 48px;
      color: var(--ion-color-primary);
    }
  }

  .iconitem__content-container {
    .iconitem__title {
      margin: 0;
      font-weight: 600;
      margin-top: 0;
      -webkit-margin-after: 8px;
      margin-block-end: 8px;
      font-size: 22px;
    }

    .iconitem__content {
      font-size: 20px;
      line-height: 135%;
      letter-spacing: -0.01em;
      margin-block-start: 5px;
      margin-block-end: 5px;
      color: var(--ion-color-medium);
      font-weight: 400;
    }
  }
}

.infobox {
  text-align: left;

  .infobox__icon-container {
    margin-block-end: 35px;

    ion-icon {
      font-size: 60px;
      color: var(--ion-color-primary);
    }
  }

  .infobox__content-container {
    .infobox__title {
      font-weight: 600;
      margin-top: 0;
      margin-block-end: 12px;
      font-size: 22px;
    }

    .infobox__content {
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.01em;
      margin-block-start: 5px;
      margin-block-end: 5px;
      color: var(--ion-color-medium);
      //text-align: justify;
    }
  }
}

.infocard {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  text-align: center;
  background: var(
    --ion-infocard-background,
    var(--ion-item-background, var(--ion-background-color, #fff))
  );
  //background: #d53815;
  border-radius: 15px;
  padding-inline-start: 45px;
  padding-inline-end: 45px;
  padding-block-end: 50px;
  padding-block-start: 35px;
  margin-block-end: 25px;

  .infocard__icon-container {
    margin-block-end: 15px;

    ion-icon {
      font-size: 100px;
      color: var(--ion-color-primary);
    }
  }

  .infocard__content-container {
    //color: white;

    .infocard__title {
      font-weight: 700;
      margin-top: 0;
      margin-block-end: 10px;
      font-size: 31px;
    }

    .infocard__content {
      font-size: 18px;
      line-height: 160%;
      letter-spacing: -0.01em;
      margin-block-start: 5px;
      margin-block-end: 5px;
      font-weight: 300;
      //color: var(--ion-color-medium);
      //text-align: justify;
    }
  }
}

.cardform {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  // background: var(
  //   --ion-infocard-background,
  //   var(--ion-item-background, var(--ion-background-color, #fff))
  // );
  background: #00000033;
  backdrop-filter: blur(88px) brightness(0.5) saturate(2.5);
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-block-end: 10px;
  padding-block-start: 0;
  margin-block-end: 0;
  border-radius: 5px;

  ion-col {
    padding-block-start: 10px;
    padding-block-end: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }
  ion-item {
    --background: transparent;
  }
}

.fullcont {
  display: flex;

  .fullcont__img-container {
    flex-grow: 1;
    flex-basis: 50%;
    img {
      object-fit: cover;
      width: 100%;
      height: 332px;
    }
  }

  .fullcont__content-container {
    flex-grow: 1;
    flex-basis: 50%;
    height: 332px;
    box-sizing: border-box;
    background: var(--ion-color-primary);
    -ms-flex-positive: 9999;
    flex-grow: 9999;

    .fullcont__content {
      height: 100%;
      //position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      display: flex;

      blockquote {
        font-size: 20px;
        position: absolute;
        left: calc(50% + 64px);
        font-family: inherit;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        vertical-align: baseline;
        background: 0 0;
        quote: none;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #fff;
        z-index: 2;
        max-width: 550px;

        &:before {
          content: url(assets/icons/left-quotes-sign.svg);
          position: absolute;
          left: -10px;
          top: -9px;
          z-index: -1;
          width: 50px;
        }

        footer {
          margin-top: 32px;
          display: block;
          font-size: 90%;
          line-height: 1.428571429;
          padding: 0;
          border-top: 0;

          cite {
            font-style: normal;
          }
          span {
            margin-left: 12px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}

.call-to-action-container {
  padding-top: 5% !important;
  padding-bottom: 5% !important;

  .call-to-action {
    color: #fff;
    position: relative;
    --ion-grid-padding: 0;

    .call-to-action__content-container {
      .call-to-action__title {
        font-size: 42px;
      }

      .call-to-action__content {
        font-size: 21px;
        color: #fff;
      }
    }

    .call-to-action__button-container {
      justify-content: flex-end;

      .call-to-action__button {
        --background: white;
        --color: black;
        font-size: larger;
        --border-radius: 30px;
        --background-hover: black;
        --color-hover: white;
        --background-activated: #000000e8;
      }
    }
  }
}

.feature {
  margin-block-start: 1px;
  margin-block-end: 1px;
  font-size: 18px;
  color: var(--ion-color-medium);

  .feature__icon {
    color: var(--ion-color-dark);
    font-size: larger;
  }
}

.products-segment {
  position: sticky;
  top: 70px;
  z-index: 99;
  backdrop-filter: blur(13px);
  min-height: 70px;
  --background: var(--ion-light-transparent-background-color);

  &.max-width-unset-on-mob {
    @media only screen and (max-width: 767px) {
      .products-segment__button {
        //max-width: unset;
        max-width: max-content;
      }
    }
  }

  .products-segment__button {
    font-weight: 600;
    text-transform: none;
    font-size: 17px;
    max-width: 120px;

    &.segment-button-checked {
      .products-segment__button__name {
        background: url(assets/images/backgrounds/gradient.jpg);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-size: cover;
        display: inline;
      }
    }

    .products-segment__icon {
      width: 40px;
      height: 40px;
      margin-block: 5px;
    }

    .products-segment__button__name {
      margin-block: 5px;
    }
  }
}

.price-box {
  margin: 0;
  padding: 42px 26px 47px;

  .price-box__header {
    padding: 0;

    .price-box__subtitle {
      margin-top: 0;
      margin-bottom: 13px;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.14em;
      text-transform: uppercase;
    }

    .price-box__title {
      font-weight: 700;
      font-size: 38px;
      line-height: 48px;
      letter-spacing: -0.03em;
      height: 48px;

      span {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: normal;
      }
    }

    .price-box__desc {
      color: var(--ion-color-dark);
      //height: 69px;
      margin-block-start: 8px;
      margin-block-end: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .price-box__content {
    padding: 0;
    padding-block-start: 5px;
    padding-block-end: 10px;
    padding-inline-start: 5px;
    padding-inline-end: 5px;
    //min-height: 265px;
    min-height: 330px;

    .price-box__feature {
      --background: transparent;
      --inner-padding-top: 7px;
      --inner-padding-bottom: 7px;
      --inner-padding-start: 0;
      --inner-padding-end: 0;
      position: relative;
      list-style: none;
      letter-spacing: -0.02em;
      font-size: 14px;
      font-weight: 400;
      //opacity: 0.7;
      height: 35px;

      .price-box__feature-icon {
        font-size: 11px;
      }
    }
  }

  &.enterprise {
    //background: var(--ion-color-primary);
    background: #d53815;

    .price-box__header {
      .price-box__subtitle {
        color: #fff;
      }

      .price-box__title {
        color: #fff;
      }

      .price-box__desc {
        color: #fff;
      }
    }

    .price-box__content {
      .price-box__feature {
        color: #fff;

        .price-box__feature-icon {
          color: #fff;
        }
      }
    }

    ion-button {
      --ion-color-base: #e94a29;
    }
  }
}

.numberbox {
  display: flex;
  border: 1px solid;
  color: var(--ion-color-dark);
  height: 3em;

  .numberbox__button {
    --border-radius: 0;
    height: 100%;
    margin: 0;
    &.decrement {
    }

    &.increment {
    }
  }

  .numberbox__input {
    --padding-start: 10px;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border-right: 1px solid;
    border-left: 1px solid;
  }
}

.refs {
  margin-block-start: 14px;
  --ion-grid-padding: 0 !important;

  .refs__row {
    .refs__col {
      padding-block: 0;
      padding-inline: 0;

      .ref {
        transition: opacity 0.1s ease-in-out;
        //pointer-events: none;
        font-size: 115px;
        height: 39px;
        //color: var(--ion-color-medium);
        margin-block-start: 17px;
        opacity: 1;
        width: 100%;

        &:hover {
          //color: var(--ion-color-dark);
          opacity: 1;
        }
      }
    }
  }
}

.summary {
  .summary__item {
    --background: transparent;
    --padding-top: 3px;
    --padding-bottom: 0;

    .summary__input {
    }
    .summary__price {
      text-align: end;
      margin-inline: 0;
      font-weight: 600;
      font-family: "Inter", sans-serif !important;
    }

    .summary__total {
      font-weight: 600;
    }
  }

  .summary__continue {
    margin-block-start: 20px;
  }
}

.card-container {
  display: flex;
  justify-content: center;

  .card {
    width: 305px;
    height: 190px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective: 600px;
    font-family: "Space Mono", monospace;

    &.turned {
      .card__front {
        transform: rotateY(180deg) !important;
      }

      .card__back {
        transform: rotateY(0deg) !important;
      }
    }

    .card__part {
      //box-shadow: 1px 1px #aaa3a3;
      top: 0;
      position: absolute;
      z-index: 1000;
      left: 0;
      display: inline-block;
      width: 305px;
      height: 190px;
      background-image: url(assets/images/card-bg.jpg),
        linear-gradient(
          to right bottom,
          #fd8769,
          #e27338,
          #f95a17,
          #cf5039,
          #ec5a48
        ); /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 8px;

      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;

      &.card__front {
        padding: 18px;
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);

        .card__square {
          border-radius: 5px;
          font-size: 30px;
          opacity: 0.5;
          color: white;
        }

        .card__front-logo {
          position: absolute;
          top: -5px;
          right: 18px;
          color: white;
        }

        .card_numer {
          display: block;
          width: 100%;
          word-spacing: 4px;
          font-size: 20px;
          letter-spacing: 1px;
          color: #fff;
          text-align: center;
          margin-bottom: 7px;
          margin-top: 37px;
          height: 29px;
        }

        .card__label {
          font-size: 8px;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.8);
          letter-spacing: 1px;
        }

        .card__info {
          margin-bottom: 0;
          margin-top: 5px;
          font-size: 14px;
          line-height: 18px;
          color: #fff;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }

      &.card__back {
        padding: 18px 0;
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);

        .card__black-line {
          margin-top: 5px;
          height: 38px;
          background-color: #303030;
        }

        .card__back-content {
          padding: 15px 15px 0;

          .card__secret {
            padding: 5px 12px;
            background-color: #fff;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: -3px;
              left: -3px;
              height: calc(100% + 6px);
              width: calc(100% - 42px);
              border-radius: 4px;
              background: repeating-linear-gradient(
                45deg,
                #ededed,
                #ededed 5px,
                #f9f9f9 5px,
                #f9f9f9 10px
              );
            }
          }

          .card__secret--last {
            color: #303030;
            text-align: right;
            margin: 0;
            font-size: 14px;
            height: 19px;
          }

          .card__back-square {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: white;
          }

          .card__back-logo {
            position: absolute;
            bottom: 15px;
            right: 15px;
            color: white;
          }
        }
      }

      .card__logo {
        font-size: 75px;
      }

      .card__space-75 {
        width: 75%;
        float: left;
      }

      .card__space-25 {
        width: 25%;
        float: left;
      }
    }
  }
}

.card:hover .card__front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.card:hover .card__back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
}

.modal-closer {
  position: absolute;
  top: -7px;
  right: 8px;
  z-index: 99;
  --padding-start: 0;
  --padding-end: 0;

  ion-icon {
    font-size: 25px;
  }
}

pinch-zoom {
  .pz-zoom-button {
    display: none !important;
  }
}

.ng-tooltip {
  position: absolute;
  max-width: 180px;
  font-size: 19px;
  text-align: center;
  color: #f8f8f2;
  padding: 8px 11px;
  background: #171717;
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
  //width: 0px;
}

.ng-tooltip-show {
  opacity: 1;
  //width: 80px;
}

.saas-address-container {
  .saas-address {
    margin: 0;
    margin-inline: 3px;
    border-radius: 15px;
    .saas-address__header {
      padding-block-start: 30px;
      padding-inline: 30px;
      .saas-address__address-name {
        font-size: 30px;
      }
    }
    .saas-address__content {
      padding-inline: 30px;
      .saas-address__item {
        --inner-padding-start: 0;
        --min-height: 32px;
        font-weight: 100;
      }
    }
    .saas-address__buttons-container {
      padding-block-end: 30px;
      padding-inline: 30px;
      display: flex;
      flex-direction: row;
      gap: 10px;

      .saas-address__button {
        margin: 0;
        flex-basis: 50%;

        &.saas-adress__remove-button {
        }
        &.saas-adress__edit-button {
        }
      }
    }

    &.selected {
      border: 2px solid var(--ion-color-primary);
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@media only screen and (min-width: 768px) {
  .landing {
    &:not(.pagehead) {
    }

    &.pagehead {
    }

    .landing-grid {
      .landing-background {
      }

      .landing-row {
        .landing-col {
          &.panaroma-container {
            panaroma,
            product {
              &.home-hero {
                min-height: 600px;
              }
            }
          }

          .landing__brandmark {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1640px) {
  .container {
    padding: 0 calc((100% - 1150px) / 2);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .container {
    padding: 0 calc((100% - 1150px) / 2);
  }

  .leading-block {
    .leading-block__title {
      font-size: 66px;
    }

    .leading-block__content {
      //font-size: 21px;
    }

    .leading-block__buttons {
      ion-button {
      }
    }

    .leading-block__cta {
      //font-size: 22px;
    }

    &.centered {
      .leading-block__content {
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .container {
    padding: 0 calc((100% - 1000px) / 2);
  }

  .leading-block {
    .leading-block__title {
      font-size: 57px;
    }

    .leading-block__content {
      font-size: 21px;
    }

    .leading-block__buttons {
      ion-button {
      }
    }

    .leading-block__cta {
      //font-size: 22px;
    }

    &.centered {
      .leading-block__content {
      }
    }
  }

  .info-block__container {
    .info-block__icon-container {
      .info-block__icon {
        font-size: 290px;
        height: 290px;
      }
    }

    .info-block {
      .info-block__subtitle {
        font-size: 13px;
      }

      .info-block__title {
        font-size: 52px;
      }

      .info-block__content {
        font-size: 21px;

        &.single-35 {
        }

        &.single-20 {
        }
      }

      .info-block__cta {
        font-size: 22px;
      }

      &.centered {
        .info-block__content {
        }
      }

      &.sticky {
      }
    }
  }

  .refs {
    margin-block-start: 14px;

    .refs__row {
      .refs__col {
        .ref {
          height: 30px;

          &:hover {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .inner-padding {
    margin-inline-start: 0;
    margin-inline-end: 0;

    ion-col {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
      padding-block-start: 8px;
      padding-block-end: 8px;
    }
  }

  .landing {
    &:not(.pagehead) {
      padding-top: 8%;
      padding-bottom: 8%;
    }

    .landing-grid {
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-top: 0;
      padding-bottom: 0;
      .landing-row {
        .landing-col {
          padding: 0px 15px;

          &.panaroma-container {
            //min-height: 432px;
            padding: 0;

            panaroma,
            product {
              &.home-hero {
                min-height: 500px;
              }
            }
          }

          .landing__brandmark {
            color: var(--ion-color-primary);
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .leading-block {
    .leading-block__title {
      font-size: 46px;
    }

    .leading-block__content {
      font-size: 19px;
    }

    .leading-block__buttons {
      ion-button {
      }
    }

    .leading-block__cta {
      //font-size: 20px;
      font-size: medium;
      margin-inline-end: 6px;
    }
  }

  .info-block__container {
    .info-block__icon-container {
      .info-block__icon {
        // min-height: 100%;
        // max-width: 100%;
        font-size: 190px;
        height: 190px;
      }
    }
    .info-block {
      .info-block__subtitle {
      }

      .info-block__title {
        font-size: 32px;
      }

      .info-block__content {
        font-size: 20px;
        margin-block-start: 15px;
      }

      .info-block__cta {
      }

      &.centered {
        padding: 0;
      }
    }
  }

  .infobox {
    .infobox__icon-container {
      ion-icon {
      }
    }

    .infobox__content-container {
      .infobox__title {
      }

      .infobox__content {
        font-size: 18px;
        line-height: 135%;
      }
    }
  }

  .iconitem {
    padding: 15px 0;

    .iconitem__icon-container {
      margin-block-end: 20px;

      ion-icon {
      }
    }

    .iconitem__content-container {
      .iconitem__title {
      }

      .iconitem__content {
        font-size: 18px;
        line-height: 135%;
      }
    }
  }

  .infocard {
    padding-inline-start: 25px;
    padding-inline-end: 25px;

    .infocard__icon-container {
      ion-icon {
      }
    }

    .infocard__content-container {
      .infocard__title {
      }

      .infocard__content {
      }
    }
  }

  .fullcont {
    .fullcont__img-container {
      //display: none;
      img {
      }
    }

    .fullcont__content-container {
      .fullcont__content {
        blockquote {
          font-size: 18px;
          left: calc(50% + 30px);
        }
      }
    }
  }

  .call-to-action-container {
    .call-to-action {
      .call-to-action__content-container {
        .call-to-action__title {
        }

        .call-to-action__content {
        }
      }

      .call-to-action__button-container {
        .call-to-action__button {
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .inner-padding {
    margin-inline-start: 0;
    margin-inline-end: 0;

    ion-col {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
      padding-block-start: 15px;
      padding-block-end: 15px;
    }
  }

  ion-header {
    z-index: 102;

    ion-toolbar {
      ion-buttons {
        &.nav-links {
          .nav-link-divider {
          }

          .nav-link {
            ion-icon {
              &.chevron {
                &.open {
                }
              }
            }

            &:hover {
            }

            &.active {
              &::after {
              }
            }

            &.opened {
              .chevron {
              }
            }

            &.ion-activated {
            }

            &::after {
            }
          }
        }

        ion-button {
          &.brandmark-button {
            margin-inline-start: 12px;
          }

          &.inapp-logotype-button {
            margin-inline-start: 12px;
            .inapp-logotype__brandmark {
            }
          }

          &.cta-link {
            ion-icon {
            }
          }

          &.sub-cta-link {
            ion-icon {
            }
          }

          &.menu-button {
            padding-inline-end: 5px;
          }

          &:hover {
            ion-icon {
              &:not(.assistive) {
              }

              &.assistive {
              }
            }
          }

          ion-icon {
            &.menu-icon {
            }

            &.brandmark {
            }

            &.logotype {
            }
          }
        }

        .lang-selector {
          &::part(text) {
          }
        }
      }
    }

    &.subnav {
      ion-toolbar {
        ion-buttons {
          &.subnav-links {
            padding-inline-end: 12px;
            .nav-link-divider {
            }

            .nav-link {
              &:last-child {
              }

              ion-icon {
                &.chevron {
                  &.open {
                  }
                }
              }

              &.active {
                //--color: var(--ion-color-primary);

                &::after {
                }
              }

              &.opened {
                .chevron {
                }
              }

              &.ion-activated {
              }

              &::after {
              }
            }
          }
        }
      }
    }
  }

  .no-padd-ver-m {
    ion-col {
      &:first-child {
        padding-block-end: 0;
      }
      &:last-child {
        padding-block-start: 0;
      }
    }
  }

  .no-pad-mob {
    padding-block: 0;
    padding-inline: 0;
  }

  .modal-closer {
    left: 8px;
    right: unset;
  }

  .landing {
    &:not(.pagehead) {
      padding-top: 0%;
      padding-bottom: 10%;

      .landing-grid {
        .landing-row {
          .landing-col {
            padding: 20px 15px;

            &.panaroma-container {
              padding: 0;
            }

            .landing__brandmark {
            }
          }
        }
      }
    }

    &.pagehead {
      padding-inline: 5px;
      .landing-grid {
        .landing-row {
          .landing-col {
            padding: 0;

            &.panaroma-container {
            }

            .landing__brandmark {
            }
          }
        }
      }
    }

    //line-height: 1.75em;

    .landing-grid {
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-top: 0;
      padding-bottom: 0;

      .landing-row {
        .landing-col {
          &.panaroma-container {
            min-height: 285px;
            //padding: 0;
          }

          .landing__brandmark {
            color: var(--ion-color-primary);
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .hgroup {
    max-width: 718px;
    margin-left: 0;
    // padding-block-start: 54px;
    // padding-block-end: 21px;
    &.large {
      .hgroup__heading {
        font-size: 51px;
        margin-block-start: 0;
      }

      .hgroup__desc {
      }
    }

    &.medium {
      .hgroup__heading {
      }

      .hgroup__desc {
      }
    }
  }

  section {
    &:not(.landing) {
      // padding-top: 5%;
      // padding-bottom: 5%;
    }

    .section__row {
      .section__col {
        //padding: 17px;
      }
    }
  }

  .integrations {
    .integrations__row {
      .integrations__col {
        text-align: left;
        .integration {
          //  font-size: 70px;

          filter: grayscale(1) brightness(0.7);

          &:hover {
            filter: grayscale(0) brightness(1);
          }
        }
      }
    }
  }

  .leading-block {
    .leading-block__title {
      font-size: 46px;
    }

    .leading-block__content {
      font-size: 19px;
    }

    .leading-block__buttons {
      ion-button {
      }
    }

    .leading-block__cta {
      font-size: medium;
      margin-inline-end: 4px;
    }
  }

  .info-block__container {
    .info-block__icon-container {
      .info-block__icon {
        // min-height: 100%;
        // max-width: 100%;
        font-size: 190px;
        height: 190px;
      }
    }
    .info-block {
      .info-block__subtitle {
      }

      .info-block__title {
        font-size: 32px;
      }

      .info-block__content {
        font-size: 19px;
      }

      .info-block__cta {
        font-size: 19px;
      }

      &.centered {
        padding: 0;
      }

      &.left-aligned-mobile {
        text-align: left !important;

        .info-block__content {
          text-align: left;
        }
      }
    }
  }

  .infobox {
    .infobox__icon-container {
      margin-block-end: 20px;

      ion-icon {
      }
    }

    .infobox__content-container {
      .infobox__title {
      }

      .infobox__content {
        font-size: 16px;
        line-height: 135%;
      }
    }
  }

  .iconitem {
    padding: 15px 0;

    .iconitem__icon-container {
      ion-icon {
      }
    }

    .iconitem__content-container {
      .iconitem__title {
      }

      .iconitem__content {
        font-size: 16px;
      }
    }
  }

  .price-box {
    .price-box__header {
      .price-box__subtitle {
      }

      .price-box__title {
      }

      .price-box__desc {
      }
    }

    .price-box__content {
      height: unset;
      min-height: 240px;

      .price-box__feature {
        .price-box__feature-icon {
        }
      }
    }

    &.enterprise {
      .price-box__header {
        .price-box__subtitle {
        }

        .price-box__title {
        }

        .price-box__desc {
        }
      }

      .price-box__content {
        .price-box__feature {
          .price-box__feature-icon {
          }
        }
      }

      ion-button {
      }
    }
  }

  .fullcont {
    display: block;

    .fullcont__img-container {
      //display: none;
      flex-basis: 100%;
      img {
        height: 205px;
      }
    }

    .fullcont__content-container {
      flex-basis: 100%;
      height: 251px;
      margin-top: -4px;

      .fullcont__content {
        blockquote {
          left: 22px;
          right: 22px;
          font-size: 15px;
        }
      }
    }
  }

  .call-to-action-container {
    padding-top: 8% !important;
    padding-bottom: 8% !important;

    .call-to-action {
      .call-to-action__content-container {
        max-width: 100%;
        margin-inline-start: 3%;
        margin-inline-end: 3%;

        .call-to-action__title {
          //font-size: 30px;
        }

        .call-to-action__content {
          //font-size: 17px;
        }
      }

      .call-to-action__button-container {
        margin-left: 0;
        padding: 0;
        justify-content: flex-start;

        .call-to-action__button {
          font-size: inherit;
        }
      }
    }
  }

  .refs {
    .refs__row {
      .refs__col {
        margin-inline-end: 28px;

        .ref {
          &:hover {
          }
        }
      }
    }
  }
}

.transparent-modal {
  --background: transparent;
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  ion-modal {
    --height: 800px;
    --width: 1400px;
  }
  .form-modal {
    &ion-modal {
      --width: 650px;
    }
  }

  .fullpage-modal {
    &ion-modal {
      --width: 100%;
      --height: 100%;
    }
  }
}

$circle-size: 120px;
$circle-background: #d9d9d9;
$circle-color: var(--ion-color-primary);
$inset-size: 105px;
$inset-color: #fbfbfb;
$transition-length: 1s;

.vt-progress-circle {
  margin: 20px auto;
  width: $circle-size;
  height: $circle-size;

  background-color: $circle-background;
  border-radius: 50%;
  .vt-progress-circle__slice,
  .vt-progress-circle__fill {
    width: $circle-size;
    height: $circle-size;
    position: absolute;
    -webkit-backface-visibility: hidden;
    transition: transform $transition-length;
    border-radius: 50%;
  }
  .vt-progress-circle__slice {
    clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
    .vt-progress-circle__fill {
      clip: rect(0px, $circle-size/2, $circle-size, 0px);
      background-color: $circle-color;
    }
  }
  .vt-progress-circle__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $inset-size;
    height: $inset-size;
    position: absolute;
    margin-left: ($circle-size - $inset-size)/2;
    margin-top: ($circle-size - $inset-size)/2;

    background-color: $inset-color;
    border-radius: 50%;
  }

  $i: 0;
  $increment: 180deg / 100;
  @while $i <= 100 {
    &[data-progress="#{$i}"] {
      .vt-progress-circle__slice.full,
      .vt-progress-circle__fill {
        transform: rotate($increment * $i);
      }
      .vt-progress-circle__fill.ko-progress-circle__bar {
        transform: rotate($increment * $i * 2);
      }
      $i: $i + 1;
    }
  }
}






.hotspot-point {
  transition: all 0.3s ease-in-out;
}
.hotspot-point:not(.scene-link) .hotspot-point:not(.section-link) {
  background: transparent;
}
.hotspot-point.scene-link {
  background-image: url('assets/icons/placeholder-3d.png');
  width: 90px;
  height: 107px;
  background-position: 0px 0px;
  background-size: 90px 107px;
}
.hotspot-point.scene-link:not(.loaded) {
  opacity: 0.5;
  cursor: wait !important;
}
.hotspot-point.scene-link.loaded {
  opacity: 1;
}
.hotspot-point.scene-link.loaded:hover {
  width: 98px;
  height: 116px;
  background-size: 98px 116px;
  cursor: pointer;
}
.hotspot-point.scene-link.loaded:hover .hotspot-point__img {
  width: 66px;
  height: 66px;
  background-position: 0px 0px !important;
  background-size: cover !important;
  border-radius: 50%;
  /* margin: 5px 0px 0px 13px; */
  position: absolute;
  top: 16px;
  left: 16px;
}
.hotspot-point.scene-link.loaded:hover .scene-link__name {
  width: 53px;
  height: 53px;
  margin: 14px 0px 0px 13px;
  font-size: 11.15px;
}
.hotspot-point.scene-link .hotspot-point__img {
  transition: all 0.3s ease-in-out;
  width: 58px;
  height: 58px;
  background-position: 0px 0px !important;
  background-size: cover !important;
  border-radius: 50%;
  /* margin: 13px 0px 0px 12px; */
  position: absolute;
  top: 16px;
  left: 16px;
}
.hotspot-point.scene-link .scene-link__name {
  transition: all 0.3s ease-in-out;
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  top: 16px;
  left: 16px;
  padding: 12px 1px;
  font-size: 12px;
  text-align: center;
  background: #00000045;
  color: #fff;
  z-index: 9;
  text-shadow: 0px 0px 4px black;
  font-weight: 600;
  display: none;
}
.hotspot-point.section-link {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 8px;
}
.hotspot-point.section-link ion-item {
  max-width: 190px;
  --inner-padding-top: 8px;
  --inner-padding-bottom: 8px;
  --inner-padding-start: 10px;
  --inner-padding-end: 0;
  --background: #000000a1;
  text-shadow: 0 0 4px #0000009e;
  font-size: 15px;
}
.hotspot-point.section-link:hover ion-item {
  --background: var(--ion-color-primary);
}
.hotspot-point.section-link .section-link__card {
  margin: 0;
  width: 180px;
  --background: #0000008f;
  box-shadow: 0px 1px 20px 3px #00000078;
}
.hotspot-point.section-link .section-link__card:hover .section-link__icon {
  left: -150px;
}
.hotspot-point.section-link .section-link__card .section-link__img {
  height: 101px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.hotspot-point.section-link .section-link__card .section-link__icon {
  transition: all ease-in-out 2s;
  position: absolute;
  left: -239px;
  top: 85px;
  width: 500px;
  height: 84px;
  mix-blend-mode: saturation;
  color: #ffffff3d;
}
.hotspot-point.section-link .section-link__card .section-link__card-header {
  padding: 8px 10px 15px;
}
.hotspot-point.section-link .section-link__card .section-link__card-header .section-link__card-title {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 110%;
}
.hotspot-point.sections-menu ion-button {
  color: white;
  --border-radius: 0;
  --border-width: 3px;
  box-shadow: 0 0 8px #00000057;
}
.hotspot-point.sections-menu ion-button:hover {
  --border-radius: 0;
  background: var(--ion-color-primary);
}
.hotspot-point.info-panel ion-button {
  color: white;
  --background-activated: transparent;
}
.hotspot-point.info-panel ion-button:hover ion-icon {
  color: var(--ion-color-primary);
}
.hotspot-point.video-infospot .hotspot__background-container {
  transition: all 0.3s ease-in-out;
  background: url('assets/icons/video-hotspot-3d-01.svg');
  width: 70px;
  height: 70px;
}
.hotspot-point.video-infospot .hotspot__background-container:hover {
  width: 80px;
  height: 80px;
}
.hotspot-point.photo-infospot .hotspot__background-container {
  transition: all 0.3s ease-in-out;
  background: url('assets/icons/image-hotspot-3d-01.svg');
  width: 70px;
  height: 70px;
}
.hotspot-point.photo-infospot .hotspot__background-container:hover {
  width: 80px;
  height: 80px;
}
.hotspot-point .hotspot__media-container {
  display: none;
}
.hotspot-point .hotspot__media-container .hotspot__media-close {
  position: absolute;
  right: -50px;
  top: -50px;
}
.hotspot-point .hotspot__media-container .hotspot__photo {
  max-width: 250px;
  box-shadow: 0px 1px 6px 2px #00000040;
}
.hotspot-point .hotspot__media-container.opened {
  display: block;
}
.hotspot-point .hotspot__icon {
  padding: 5px;
  border: 2px solid;
  border-radius: 50%;
  font-size: x-large;
  transition: font-size 0.3s ease-in-out;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.75);
}
.hotspot-point .hotspot__icon.hidden {
  display: none;
}
.hotspot-point .explanation {
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  font-size: 15px;
  opacity: 0;
  width: 200px;
  left: -81px;
  text-align: center;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.75);
}
.hotspot-point:hover {
  cursor: pointer;
  border-color: white;
}
.hotspot-point:hover ion-icon {
  color: white;
}
.hotspot-point:hover .explanation {
  opacity: 1;
}
.hotspot-preview.hotspot-preview__video {
  width: 100%;
  height: 100%;
}
.hotspot-preview.hotspot-preview__image {
  max-width: 100%;
  max-height: 100%;
}
