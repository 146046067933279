// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
// :root {
//   /** primary **/
//   --ion-color-primary: #d53815;
//   --ion-color-primary-rgb: 213, 56, 21;
//   --ion-color-primary-contrast: #ffffff;
//   --ion-color-primary-contrast-rgb: 255, 255, 255;
//   --ion-color-primary-shade: #bb3112;
//   --ion-color-primary-tint: #d94c2c;

//   /** secondary **/
//   --ion-color-secondary: #3dc2ff;
//   --ion-color-secondary-rgb: 61, 194, 255;
//   --ion-color-secondary-contrast: #ffffff;
//   --ion-color-secondary-contrast-rgb: 255, 255, 255;
//   --ion-color-secondary-shade: #36abe0;
//   --ion-color-secondary-tint: #50c8ff;

//   /** tertiary **/
//   --ion-color-tertiary: #5260ff;
//   --ion-color-tertiary-rgb: 82, 96, 255;
//   --ion-color-tertiary-contrast: #ffffff;
//   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//   --ion-color-tertiary-shade: #4854e0;
//   --ion-color-tertiary-tint: #6370ff;

//   /** success **/
//   --ion-color-success: #2dd36f;
//   --ion-color-success-rgb: 45, 211, 111;
//   --ion-color-success-contrast: #ffffff;
//   --ion-color-success-contrast-rgb: 255, 255, 255;
//   --ion-color-success-shade: #28ba62;
//   --ion-color-success-tint: #42d77d;

//   /** warning **/
//   --ion-color-warning: #ffc409;
//   --ion-color-warning-rgb: 255, 196, 9;
//   --ion-color-warning-contrast: #000000;
//   --ion-color-warning-contrast-rgb: 0, 0, 0;
//   --ion-color-warning-shade: #e0ac08;
//   --ion-color-warning-tint: #ffca22;

//   /** danger **/
//   --ion-color-danger: #eb445a;
//   --ion-color-danger-rgb: 235, 68, 90;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255, 255, 255;
//   --ion-color-danger-shade: #cf3c4f;
//   --ion-color-danger-tint: #ed576b;

//   /** dark **/
//   --ion-color-dark: #222428;
//   --ion-color-dark-rgb: 34, 36, 40;
//   --ion-color-dark-contrast: #ffffff;
//   --ion-color-dark-contrast-rgb: 255, 255, 255;
//   --ion-color-dark-shade: #1e2023;
//   --ion-color-dark-tint: #383a3e;

//   /** medium **/
//   //--ion-color-medium: #92949c;
//   --ion-color-medium: #4c4c4c;
//   --ion-color-medium-rgb: 146, 148, 156;
//   --ion-color-medium-contrast: #ffffff;
//   --ion-color-medium-contrast-rgb: 255, 255, 255;
//   --ion-color-medium-shade: #808289;
//   --ion-color-medium-tint: #9d9fa6;

//   /** light **/
//   --ion-color-light: #f4f5f8;
//   --ion-color-light-rgb: 244, 245, 248;
//   --ion-color-light-contrast: #000000;
//   --ion-color-light-contrast-rgb: 0, 0, 0;
//   --ion-color-light-shade: #d7d8da;
//   --ion-color-light-tint: #f5f6f9;

//   --ion-light-transparent-background-color:#ffffff54;
//   --ion-transparent-background-color: #ffffff85;
//   --ion-item-hover-background: #d3d3d34a;

//   --ion-light-background-color: #f5f5f5;
//   --ion-infocard-background: #ffffff;

//   --integration-filter: grayscale(1) brightness(0.7) opacity(0.6);

// }


:root{
  --ion-color-primary: #e94a29;
    --ion-color-primary-rgb: 233, 74, 41;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #cd4124;
    --ion-color-primary-tint: #eb5c3e;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-hover-background: #131313;

    --ion-light-background-color: #050505; 

    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-transparent-background-color: #000000b3;
    --ion-light-transparent-background-color:#00000054;

    //--ion-transparent-background-color: #09090985;


    --ion-toolbar-background: #000000;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    --ion-infocard-background: #0a0a0a;

    --integration-filter: grayscale(1) brightness(0.7) opacity(0.6);
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #e94a29;
//     --ion-color-primary-rgb: 233, 74, 41;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #cd4124;
//     --ion-color-primary-tint: #eb5c3e;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-hover-background: #131313;

//     --ion-light-background-color: #050505; 

//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-transparent-background-color: #000000b3;
//     --ion-light-transparent-background-color:#00000054;

//     //--ion-transparent-background-color: #09090985;


//     --ion-toolbar-background: #000000;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;

//     --ion-infocard-background: #0a0a0a;

//     --integration-filter: grayscale(1) brightness(0.7) opacity(0.6);
//   }  
// }